import { useState, useEffect } from 'react'
import { NextImage } from '@/components/Image/NextImage'
import CTA from '@/components/CTA'
import { enable3upFeature } from '@/lib/contentful/module'
import Markdown from '@/components/atoms/Markdown'

const module3upFeature = (props) => {
  const [content, setContent] = useState(props?.fields)
  const { preview } = props

  const initPreviewData = async () => {
    try {
      const res = await enable3upFeature(props, preview)

      // console.log('the content', content)

      setContent(res)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (preview) {
      initPreviewData()
    }
  }, [])

  const ctaClassName =
    '!flex uppercase font-bold tracking-widest text-sm leading-[15px] !border-b-2 !border-primary !border-t-0 !border-l-0 !border-r-0 w-fit cursor-pointer mx-auto pb-[4px]'

  return (
    <div className="module-3up-feature container my-20 rich-text-content">
      {content?.title && (
        <div className="pb-5 text-center mx-auto w-full md:w-10/12">
          <Markdown {...{ fields: { markdownText: content?.title } }} preview={preview} />
        </div>
      )}

      <div className="lg:flex">
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-3 my-0 w-full">
          <div className="pt-2 md:pt-0">
            <div className="flex aspect-square pb-5">
              <div className="relative w-full h-full">
                {content?.image1?.fields?.file?.url && (
                  <NextImage
                    className="w-full object-cover h-50"
                    src={`https:${content?.image1?.fields?.file?.url}`}
                    layout="fill"
                    alt={content?.image1?.fields?.file?.description ?? ''}
                    quality={90}
                    objectFit="cover"
                    objectPosition="center"
                  />
                )}
              </div>
            </div>
            <div className="px-2 text-center">
              {content?.title1 && (
                <Markdown {...{ fields: { markdownText: content?.title1 } }} preview={preview} />
              )}
              {content?.link1 && content?.linkText1 && (
                <CTA
                  className={ctaClassName}
                  label={content?.linkText1}
                  url={content?.link1}
                  background={'black'}
                  moduleProps={props}
                />
              )}
            </div>
          </div>
          <div>
            <div className="flex aspect-square pb-5">
              <div className="relative w-full h-full">
                {content?.image2?.fields?.file?.url && (
                  <NextImage
                    className="w-full object-cover h-50"
                    src={`https:${content?.image2?.fields?.file?.url}`}
                    layout="fill"
                    alt={content?.image2?.fields?.file?.description ?? ''}
                    quality={90}
                    objectFit="cover"
                    objectPosition="center"
                  />
                )}
              </div>
            </div>
            <div class="px-2 text-center">
              {content?.title2 && (
                <Markdown {...{ fields: { markdownText: content?.title2 } }} preview={preview} />
              )}
              {content?.link2 && content?.linkText2 && (
                <CTA
                  className={ctaClassName}
                  label={content?.linkText2}
                  url={content?.link2}
                  background={'black'}
                  moduleProps={props}
                />
              )}
            </div>
          </div>
          <div class="pb-5 md:pb-0">
            <div class="flex aspect-square pb-5">
              <div className="relative w-full h-full">
                {content?.image3?.fields?.file?.url && (
                  <NextImage
                    className="w-full object-cover h-50"
                    src={`https:${content?.image3?.fields?.file?.url}`}
                    layout="fill"
                    alt={content?.image3?.fields?.file?.description ?? ''}
                    quality={90}
                    objectFit="cover"
                    objectPosition="center"
                  />
                )}
              </div>
            </div>
            <div class="px-2 text-center">
              {content?.title3 && (
                <Markdown {...{ fields: { markdownText: content?.title3 } }} preview={preview} />
              )}
              {content?.link3 && content?.linkText3 && (
                <CTA
                  className={ctaClassName}
                  label={content?.linkText3}
                  url={content?.link3}
                  background={'black'}
                  moduleProps={props}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default module3upFeature
